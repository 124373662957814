import React from "react";
import Card from "../components/layout/card/card";
import Layout from "../components/layout/layout/layout";
import { BiSolidUserDetail } from "react-icons/bi";
import TableCRM from "../components/projectComponents/table/tableCrm";

function CRM() {
  return (
    <Layout padding={"1rem 1rem 1rem 0rem"}>
      <Card
        cardTitles={`Customer CRM`}
        cardDescriptions={"List of all customers"}
        className={"card-qirs"}
        flex={1}
        icon={<BiSolidUserDetail />}
        margin={"0px"}
        borderRadius={"1.5rem"}
      >
        <TableCRM />
      </Card>
    </Layout>
  );
}

export default CRM;
