import { buttonBaseClasses } from "@mui/material";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#d72f59",
    },
    secondary: {
      main: "#262626",
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize", // Capitalize button text
          borderRadius: "5rem",
          padding: ".75rem 2rem",
          
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          input: {
            padding: ".7rem 1rem !important",
            paddingBlock: ".75rem !important",
          },
          label: {
            margin: "-.7rem 1rem !important",
          },
        },
      },
    },

    MuiFormControl: {
      styleOverrides: {
        root: {
          width: "100%",
          label: {
            margin: "-.4rem 0rem ",
            padding: "0rem 0rem ",
            focus: {
              margin: "0rem 0rem ",
            }
          },
          '& .MuiInputLabel-root.Mui-focused': {
            margin: "0.1rem  ",
          },
          '& .MuiOutlinedInput-input:after': {
            color: '#1976d2', // Change to your desired color for after
          },
          
        },
        
      },
    },


},
  typography: {
    fontFamily: [
      "Inter",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

export default theme;
