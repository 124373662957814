import React from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "./docStyle.css";

const loadImage = (src) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = src;
    img.onload = () => resolve(img);
    img.onerror = (err) => reject(err);
  });

const generatePDF = async (entry, user, receipts) => {
  if (!receipts.items) {
    console.error("Receipts items are undefined");
    return;
  }

  const doc = new jsPDF("p", "mm", "a4");

  const logoImg = await loadImage(user.logoUrl);
  const logoBase64 = await new Promise((resolve) => {
    const canvas = document.createElement("canvas");
    canvas.width = logoImg.width;
    canvas.height = logoImg.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(logoImg, 0, 0);
    resolve(canvas.toDataURL("image/png"));
  });

  const receipt = document.createElement("div");
  receipt.className = "receipt-container";
  receipt.innerHTML = `
    <div class="stylingDocTop"></div>
    <div class="receiptsContent">
      <div class="topSections">
        <div class="left">
          <h2>${user.company_name}</h2>
          <p>${user.company_address}</p>
        </div>
        <div class="right">
          <div class="logoContainer">
            <img src="${logoBase64}" alt="Company Logo" />
          </div>
        </div>
      </div>
      <h1 class="receipt-header">RECEIPT</h1>
      <div class="receipt-info">
        <div class="left">
          <h2>Receipt for</h2>
          <p>${receipts.customer_name}</p>
          <p><b>${receipts.company_name}</b></p>
          <p>${receipts.company_address}</p>
        </div>
        <div class="mid">
          <h2>Project</h2>
          <p>${receipts.project_name}</p>
        </div>
        <div class="right">
          <h2>Receipt ID</h2>
          <p>${receipts.document_number}</p>
          <h2>Receipt date</h2>
          <p>${receipts.date_created}</p>
        </div>
      </div>
      <div class="receipt-items">
        <h2></h2>
        <div class="label">
          <h2 style="flex: 5; text-align: left;">Description</h2>
          <h2 style="flex: 1;">Qty</h2>
          <h2 style="flex: 1;">Unit price</h2>
          <h2 style="flex: 1; text-align: right;">Total price</h2>
        </div>
        ${receipts.items.map((item, index) => `
          <div class="receipt-item" key=${index}>
            <div class="description" style="flex: 5; text-align: left;">
              <h3>${item.title}</h3>
              <p>${item.description}</p>
            </div>
            <div class="quantity" style="flex: 1; text-align: center;">
              <p>${item.quantity}</p>
            </div>
            <div class="unit-price" style="flex: 1; text-align: center;">
              <p>${user.curency} ${item.unit_price}</p>
            </div>
            <div class="total" style="flex: 1; text-align: right;">
              <p>${user.curency} ${item.total_price_items}</p>
            </div>
          </div>
        `).join('')}
        <h2></h2>
      </div>
      <div class="receipt-footer">
        <div class="notes" style="flex: 6;">
          <p>Notes:</p>
          <p>${receipts.notes}</p>
        </div>
        <div class="label">
          <p><strong style="text-align: left !important;">Subtotal:</strong></p>
          <p><strong style="text-align: left !important;">Discount:</strong></p>
        </div>
        <div class="total-price" style="text-align: right; width: 10rem;">
          <p>${user.curency} ${receipts.sub_totals}</p>
          <p>${receipts.discounts} %</p>
          <h1 style="font-size: 2rem;">${user.curency} ${receipts.total_price}</h1>
        </div>
      </div>
    </div>
    <div class="stylingDocSpacing"></div>
    <div class="docFooterText">
    </div>
    <div class="stylingDocBottom"></div>
  `;

  document.body.appendChild(receipt);

  const canvas = await html2canvas(receipt, { scale: 2 });
  const imgData = canvas.toDataURL("image/png");

  const imgWidth = 210; // A4 width in mm
  const pageHeight = 297; // A4 height in mm
  const imgHeight = (canvas.height * imgWidth) / canvas.width;
  let heightLeft = imgHeight;

  let position = 0;

  doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
  heightLeft -= pageHeight;

  while (heightLeft >= 0) {
    position = heightLeft - imgHeight;
    doc.addPage();
    doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;
  }

  doc.save("receipt.pdf");

  document.body.removeChild(receipt);
};

const ReceiptTemplate = ({ entry, receipts, user }) => {
  return (
    <div className="receipt-container">
      <div className="stylingDocTop"></div>
      <div className="receiptsContent">
        <div className="topSections">
          <div className="left">
            <h2>{user.company_name}</h2>
            <p>{user.company_address}</p>
          </div>
          <div className="right">
            <div className="logoContainer">
              <img src={user.logoUrl} alt="Company Logo" />
            </div>
          </div>
        </div>
        <h1 className="receipt-header">RECEIPT</h1>
        <div className="receipt-info">
          <div className="left">
            <h2>Receipt for</h2>
            <p>{receipts.customer_name}</p>
            <p><b>{receipts.company_name}</b></p>
            <p>{receipts.company_address}</p>
          </div>
          <div className="mid">
            <h2>Project</h2>
            <p>{receipts.project_name}</p>
          </div>
          <div className="right">
            <h2>Receipt ID</h2>
            <p>{receipts.document_number}</p>
            <h2>Receipt date</h2>
            <p>{receipts.date_created}</p>
          </div>
        </div>
        <div className="receipt-items">
          <h2></h2>
          <div className="label">
            <h2 style={{ flex: 5, textAlign: "left" }}>Description</h2>
            <h2 style={{ flex: 1 }}>Qty</h2>
            <h2 style={{ flex: 1 }}>Unit price</h2>
            <h2 style={{ flex: 1, textAlign: "right" }}>Total price</h2>
          </div>
          {receipts.items && receipts.items.map((item, index) => (
            <div className="receipt-item" key={index}>
              <div className="description" style={{ flex: 5, textAlign: "left" }}>
                <h3>{item.title}</h3>
                <p>{item.description}</p>
              </div>
              <div className="quantity" style={{ flex: 1, textAlign: "center" }}>
                <p>{item.quantity}</p>
              </div>
              <div className="unit-price" style={{ flex: 1, textAlign: "center" }}>
                <p>{user.curency} {item.unit_price}</p>
              </div>
              <div className="total" style={{ flex: 1, textAlign: "right" }}>
                <p>{user.curency} {item.total_price_items}</p>
              </div>
            </div>
          ))}
          <h2></h2>
        </div>
        <div className="receipt-footer">
          <div className="notes" style={{ flex: 6 }}>
            <p>Notes:</p>
            <p>{receipts.notes}</p>
          </div>
          <div className="label">
            <p><strong style={{ textAlign: "left !important" }}>Subtotal:</strong></p>
            <p><strong style={{ textAlign: "left !important" }}>Discount:</strong></p>
          </div>
          <div className="total-price" style={{ textAlign: "right", width: "10rem" }}>
            <p>{user.curency} {receipts.sub_totals}</p>
            <p>{receipts.discounts} %</p>
            <h1 style={{ fontSize: "2rem" }}>{user.curency} {receipts.total_price}</h1>
          </div>
        </div>
      </div>
      <div className="stylingDocSpacing"></div>
      <div className="docFooterText">
        <p>design by Putra Naaim</p>
      </div>
      <div className="stylingDocBottom"></div>
    </div>
  );
};

export { ReceiptTemplate, generatePDF };