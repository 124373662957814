import React, { useState, useEffect } from "react";
import { DataGrid, GridActionsCellItem, useGridApiRef, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { API_GET_CUSTOMER_CRM } from "../../../apiConfig";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { format } from "date-fns";
import { WEB_SOCKET_URL } from "../../../apiConfig";

const TableCRM = () => {
  const [customers, setCustomers] = useState([]);
  const apiRef = useGridApiRef();

  const token = localStorage.getItem("accessToken"); // Retrieve the token from local storage

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(API_GET_CUSTOMER_CRM, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const formattedData = response.data.map((customer) => ({
          ...customer,
          last_project_date: customer.last_project_date
            ? format(new Date(customer.last_project_date), "dd/MM/yyyy")
            : "",
        }));
        setCustomers(formattedData);
      } catch (error) {
        console.error("Error fetching the customer data:", error);
      }
    };

    fetchData();
  }, [token]);

  useEffect(() => {
    const ws = new WebSocket(WEB_SOCKET_URL);

    ws.onopen = () => {
      console.log("Connected to WebSocket server");
    };

    ws.onmessage = (event) => {
      const updatedCustomer = JSON.parse(event.data);
      const formattedCustomer = {
        ...updatedCustomer,
        last_project_date: updatedCustomer.last_project_date
          ? format(new Date(updatedCustomer.last_project_date), "dd/MM/yyyy")
          : "",
      };
      setCustomers((prevCustomers) =>
        prevCustomers.map((customer) =>
          customer.id === updatedCustomer.id ? formattedCustomer : customer
        )
      );
    };

    ws.onclose = () => {
      console.log("Disconnected from WebSocket server");
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    return () => {
      ws.close();
    };
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_GET_CUSTOMER_CRM}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Remove the deleted customer from the state
      setCustomers((prevCustomers) =>
        prevCustomers.filter((customer) => customer.id !== id)
      );
    } catch (error) {
      console.error("Error deleting the customer:", error);
    }
  };

  const handleEdit = (id) => {
    apiRef.current.startRowEditMode({ id });
  };

  const handleSave = async (id) => {
    const updatedCustomer = apiRef.current.getRow(id);
    console.log("Updated Customer:", updatedCustomer); // Log the updated customer data

    const existingCustomer = customers.find((customer) => customer.id === id);

    try {
      const response = await axios.put(
        `${API_GET_CUSTOMER_CRM}/${id}`,
        updatedCustomer,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const formattedResponse = {
        ...response.data,
        last_project_date: response.data.last_project_date
          ? format(new Date(response.data.last_project_date), "dd/MM/yyyy")
          : existingCustomer.last_project_date,
      };
      // Update the state with the response data from the backend to ensure consistency
      setCustomers((prevCustomers) =>
        prevCustomers.map((customer) =>
          customer.id === id
            ? { ...existingCustomer, ...formattedResponse }
            : customer
        )
      );
      apiRef.current.stopRowEditMode({ id });
    } catch (error) {
      console.error("Error saving the customer data:", error);
    }
  };

  const processRowUpdate = async (newRow) => {
    const existingCustomer = customers.find(
      (customer) => customer.id === newRow.id
    );

    try {
      const response = await axios.put(
        `${API_GET_CUSTOMER_CRM}/${newRow.id}`,
        newRow,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const formattedResponse = {
        ...response.data,
        last_project_date: response.data.last_project_date
          ? format(new Date(response.data.last_project_date), "dd/MM/yyyy")
          : existingCustomer.last_project_date,
      };
      // Update the state with the response data from the backend to ensure consistency
      setCustomers((prevCustomers) =>
        prevCustomers.map((customer) =>
          customer.id === newRow.id
            ? { ...existingCustomer, ...formattedResponse }
            : customer
        )
      );
      return formattedResponse;
    } catch (error) {
      console.error("Error processing row update:", error);
      throw error;
    }
  };

  const handleProcessRowUpdateError = (error) => {
    console.error("Error processing row update:", error);
  };

  const columns = [
    {
      field: "customer_name",
      headerName: "Customer Name",
      flex: 1,
      editable: true,
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      flex: 1,
      editable: true,
    },
    { field: "email", headerName: "Email", flex: 1, editable: true },
    {
      field: "company_name",
      headerName: "Company Name",
      flex: 1,
      editable: true,
    },
    {
      field: "company_address",
      headerName: "Company Address",
      flex: 1,
      editable: true,
    },
    {
      field: "total_projects",
      headerName: "Total Projects",
      flex: 1,
      type: "number",
    },
    { field: "last_project_name", headerName: "Last Project Name", flex: 1 },
    { field: "last_project_date", headerName: "Last Project Date", flex: 1 },
    {
      field: "total_sales",
      headerName: "Total Sales",
      flex: 1,
      type: "number",
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      flex: 1,
      getActions: (params) => {
        const isInEditMode = apiRef.current.getRowMode(params.id) === "edit";
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => handleDelete(params.id)}
          />,
          <GridActionsCellItem
            icon={isInEditMode ? <SaveIcon /> : <EditIcon />}
            label={isInEditMode ? "Save" : "Edit"}
            onClick={() =>
              isInEditMode ? handleSave(params.id) : handleEdit(params.id)
            }
          />,
        ];
      },
    },
  ];


  return (
    <DataGrid
      className="datagridCRM"
      rows={customers}
      columns={columns}
      getRowId={(row) => row.id}
      processRowUpdate={processRowUpdate}
      onProcessRowUpdateError={handleProcessRowUpdateError}
      editMode="row"
      apiRef={apiRef}
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector
      slots={{ toolbar: GridToolbar }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
        },
      }}
    />
  );
};

export default TableCRM;
