import React from "react";
import SideMenu from "../../components/navi/sideMenu/sideMenu";
import LogoWeb from "../../asset/logoBlack.png";
import ItemMenu from "../../components/navi/sideMenu/itemMenu";
import SubMenu from "../../components/navi/sideMenu/subMenu";
import { logout } from "../../utils/auth";
import { BiSolidDashboard } from "react-icons/bi";
import { BiSolidFileExport } from "react-icons/bi";
import { BiSolidFileImport } from "react-icons/bi";
import { BiSolidFileBlank } from "react-icons/bi";
import { BiSolidUserDetail } from "react-icons/bi";
import { BiSolidFilePlus } from "react-icons/bi";
import { BiSolidCog } from "react-icons/bi";
import { BiSolidLogOut } from "react-icons/bi";



function Menu() {
  return (
    <SideMenu width="20rem" padding={"10px"} logo={LogoWeb}>
      <SubMenu flex={2} marginTop={".75rem"}>
        <ItemMenu
          icon={<BiSolidDashboard />}
          text="Dashboard"
          route={"/main/dashboard"}
        />
        <ItemMenu
          icon={<BiSolidFileExport />}
          text="Quotation"
          route={"/main/quotation"}
        />
        <ItemMenu
          icon={<BiSolidFileImport />}
          text="Invoice"
          route={"/main/invoice"}
        />
        <ItemMenu
          icon={<BiSolidFileBlank />}
          text="Receipt"
          route={"/main/receipt"}
        />
        <ItemMenu icon={<BiSolidUserDetail />} text="CRM" route={"/main/crm"} />
      </SubMenu>
      <SubMenu title={"CREATE NOW!"} flex={3}>
        <ItemMenu
          icon={<BiSolidFilePlus />}
          text="New Quotation"
          route={"/main/createQuotation"}
        />
        <ItemMenu
          icon={<BiSolidFilePlus />}
          text="New Invoice"
          route={"/main/createInvoice"}
        />
        <ItemMenu
          icon={<BiSolidFilePlus />}
          text="New Receipt"
          route={"/main/createReceipt"}
        />
      </SubMenu>
      <SubMenu borderTop={".1rem solid black"} >
        <ItemMenu
          icon={<BiSolidCog />}
          text="Settings"
          route={"/main/settings"}
        />
        <ItemMenu
          icon={<BiSolidLogOut />}
          text="Logout"
          onClick={logout}
          route={"/main/logout"}
        />
      </SubMenu>
    </SideMenu>
  );
}

export default Menu;
