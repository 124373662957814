import React from "react";
import "./sideMenu.css";

const SideMenu = ({ width, children, logo, logoEnabled = true, className, padding }) => {
  return (
    <div className="side-menu-container" style={{ width: width, padding: padding }}>
      <div className={`side-menu ${className}`}>
        <div className="side-menu-logo" style={{ display: logoEnabled ? 'block' : 'none' }}>
          <img src={logo} alt="Logo" />
        </div>
        {children}
      </div>
    </div>
  );
};

export default SideMenu;