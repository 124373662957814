import React, { useEffect, useState } from "react";
import Layout from "../components/layout/layout/layout";
import LayoutRow from "../components/layout/layoutRow/layoutRow";
import Card from "../components/layout/card/card";
import { BiSolidFileExport } from "react-icons/bi";
import { BiSolidFileImport } from "react-icons/bi";
import { BiLogoAlgolia } from "react-icons/bi";
import { BiPlusMedical } from "react-icons/bi";
import { API_GET_COUNTER } from "../apiConfig";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ChartTargetSales from "../components/projectComponents/barChart/chartTargetSales";
import ChartCurrentSales from "../components/projectComponents/barChart/chartCurrentSales";

const Dashboard = () => {
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCounter = async () => {
      try {
        const response = await axios.get(API_GET_COUNTER, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Adjust if using different auth method
          },
        });
        const result = response.data;
        setData(result);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
        window.location.reload();
      }
    };

    fetchCounter();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const handleGoToQuotations = () => {
    navigate("/main/quotation"); // Navigate to the AboutPage
  };
  const handleGoToInvoices = () => {
    navigate("/main/invoice"); // Navigate to the AboutPage
  };

  return (
    <Layout padding={"0 1rem 1rem 0 "} direction={"column"}>
      <LayoutRow flex={1} padding={"1rem"}>
        <Card
          flex={1}
          styles={"style-primary"}
          cardTitles={"Current Sales"}
          cardDescriptions={"Sales for this month"}
          borderRadius={"1rem"}
        >
          <ChartCurrentSales />
        </Card>
        <Card
          flex={1}
          styles={"style-glass"}
          cardTitles={"Target Sales"}
          cardDescriptions={"Sales target for this month"}
          borderRadius={"1rem"}
        >
          <ChartTargetSales />
        </Card>
      </LayoutRow>
      <LayoutRow flex={1} padding={"0 1rem 1rem 1rem "}>
        <Card
          flex={1}
          styles={"style-hollow"}
          padding={"0px"}
        >
          <h1 style={{ textAlign: "left" }}>Current Status :</h1>
          <LayoutRow flex={1}>
            <Card
              flex={1}
              icon={<BiSolidFileExport />}
              borderRadius={"1rem"}
              iconbtn={<BiPlusMedical />}
              onClick={handleGoToQuotations}
            >
              <div className="counterDashboard">
                <h1>{data.counterTotalQuotations}</h1>
                <p>Total quotations being created this month</p>
              </div>
            </Card>
            <Card
              flex={1}
              icon={<BiSolidFileImport />}
              borderRadius={"1rem"}
              iconbtn={<BiPlusMedical />}
              onClick={handleGoToInvoices}
            >
              <div className="counterDashboard">
                <h1>{data.countInvoicesDueBeyondWeek}</h1>
                <p>Total Invoice pending for payments</p>
              </div>
            </Card>
            <Card
              flex={1}
              icon={<BiLogoAlgolia />}
              borderRadius={"1rem"}
              iconbtn={<BiPlusMedical />}
              onClick={handleGoToQuotations}
            >
              <div className="counterDashboard">
                <h1>{data.counterTotalOnGoing}</h1>
                <p>Total curent "Active" project </p>
              </div>
            </Card>
            <Card flex={1} styles={"style-hollow"}></Card>
          </LayoutRow>
        </Card>
      </LayoutRow>
    </Layout>
  );
};

export default Dashboard;
