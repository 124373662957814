import React, { useState, useEffect } from "react";
import Layout from "../../layout/layout/layout";
import LayoutRow from "../../layout/layoutRow/layoutRow";
import Avatar from "../../dataDisplay/avatar/avatar";
import useCompany from "../../../utils/userCompany";
import Input from "../../dataInput/input/input";
import { BiSave } from "react-icons/bi";
import Button from "@mui/material/Button";
import axios from "axios";
import Tooltip from "../../dataDisplay/tooltip/tooltip";
import {
  API_PUT_COMPANY_DETAILS,
  API_PUT_COMPANY_LOGO,
} from "../../../apiConfig";

const DocumentSettingForm = () => {
  const user = useCompany();
  const isDemoUser = user?.username === "demoUser";

  const [formData, setFormData] = useState({
    companyName: "",
    companyAddress: "",
    curency2: "",
  });
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [loadingAvatar, setLoadingAvatar] = useState(false);
  const [error, setError] = useState(null);
  const [avatar, setAvatar] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleCompanyUpdate = async (e) => {
    if (isDemoUser) {
      alert("'demoUser' does not have permission to change these settings");
      return;
    }
    e.preventDefault();
    setLoadingProfile(true);
    setError(null);
    const { companyName, companyAddress, curency2 } = formData;
    try {
      const response = await axios.put(
        API_PUT_COMPANY_DETAILS,
        {
          company_name: companyName,
          company_address: companyAddress,
          curency: curency2,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      console.log("API response:", response.data);
      alert("Company details updated successfully");
    } catch (error) {
      setError(error.response?.data?.message || "Error updating profile");
    } finally {
      setLoadingProfile(false);
    }
  };

  const handleAvatarClick = () => {
    if (isDemoUser) {
      alert("'demoUser' does not have permission to change profile picture");
      return;
    }
    document.getElementById("avatarUpload").click();
  };

  const handleAvatarChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      setLoadingAvatar(true);
      const formData = new FormData();
      formData.append("company_logo", file);

      try {
        const response = await axios.put(API_PUT_COMPANY_LOGO, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "Content-Type": "multipart/form-data",
          },
        });
        setAvatar(response.data.companyLogoUrl);
        alert("Profile picture updated successfully");
      } catch (error) {
        setError(
          error.response?.data?.message || "Error updating profile picture"
        );
      } finally {
        setLoadingAvatar(false);
      }
    }
  };

  useEffect(() => {
    if (user) {
      console.log("User data:", user);
      setFormData({
        companyName: user.company_name,
        companyAddress: user.company_address,
        curency2: user.curency, // Ensure this field is correctly initialized
      });
      setAvatar(user.companyLogoUrl); // Use companyLogoUrl from user
    }
  }, [user]);

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <Layout direction={"column"} padding={"1rem"}>
      <LayoutRow flex={1}>
        <Layout
          flex={5}
          padding={"1.5rem"}
          className={"settingContent"}
          direction={"column"}
        >
          <div className="titleSettingContainer">
            <h1>Document Setting</h1>
            <p>Edit your setting by inputting new information and "Update".</p>
          </div>
          <LayoutRow className={"inputSettingContainer"} flex={1}>
            <div className="left">
              <Tooltip
                info={
                  isDemoUser
                    ? "demoUser does not have permission to change company logo"
                    : "Change company logo"
                }
              >
                <Avatar
                  width={"12rem"}
                  image={avatar}
                  defaultText={`${user.company_name}`}
                  onClick={handleAvatarClick}
                />
              </Tooltip>
              <input
                type="file"
                id="avatarUpload"
                style={{ display: "none" }}
                accept="image/*"
                onChange={handleAvatarChange}
              />
              <form onSubmit={handleCompanyUpdate}>
                <Input
                  styles="style-three"
                  label="Company Name"
                  showLabel="true"
                  name="companyName"
                  placeholder="Company Name"
                  type="text"
                  width={"80%"}
                  value={formData.companyName}
                  onChange={handleInputChange}
                />
                <Input
                  styles="style-three"
                  label="Company Address"
                  showLabel="true"
                  name="companyAddress"
                  placeholder="Company Address"
                  type="text"
                  width={"80%"}
                  value={formData.companyAddress}
                  onChange={handleInputChange}
                />
                <Input
                  styles="style-three"
                  label="Curency"
                  showLabel="true"
                  name="curency2"
                  placeholder="RM, USD, etc."
                  type="text"
                  width={"80%"}
                  value={formData.curency2}
                  onChange={handleInputChange}
                />

                <div className="settingActionBtn">
                  <Button
                    variant="contained"
                    style={{ width: "10rem" }}
                    type="submit"
                    startIcon={<BiSave />}
                  >
                    {loadingProfile ? "Updating..." : "Update"}
                  </Button>
                </div>
                {error && <p style={{ color: "red" }}>{error}</p>}
              </form>
            </div>
            <div className="right"></div>
          </LayoutRow>
        </Layout>
      </LayoutRow>
    </Layout>
  );
};

export default DocumentSettingForm;
