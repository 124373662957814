import { useState, useEffect } from "react";
import axios from "axios";
import { getUserFromToken, logout, refreshAccessToken } from "./auth";
import { WEB_SOCKET_URL, API_GET_USER_DETAILS } from "../apiConfig";

const useCompany = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      let userData = getUserFromToken();

      if (!userData) {
        const newToken = await refreshAccessToken();
        if (newToken) {
          userData = getUserFromToken();
        }
      }

      if (userData) {
        try {
          const response = await axios.get(API_GET_USER_DETAILS, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          });

          const companyLogoUrl = response.data.company_logo
            ? `https://qirs.axstudio.xyz/uploads/${response.data.company_logo}`
            : "";

          console.log("Fetched user data:", response.data); // Log the fetched user data

          setUser({
            ...response.data,
            companyLogoUrl: companyLogoUrl,
          });
        } catch (error) {
          console.error("Error fetching user data:", error);
          logout();
        }
      } else {
        logout();
      }
    };

    fetchUserData();

    const ws = new WebSocket(WEB_SOCKET_URL);

    ws.onmessage = (event) => {
      const { type, data } = JSON.parse(event.data);
      if (type === "user") {
        const companyLogoUrl = data.company_logo
          ? `https://qirs.axstudio.xyz/uploads/${data.company_logo}`
          : "";

        console.log("WebSocket user update:", data); // Log the WebSocket user update

        setUser({
          ...data,
          companyLogoUrl: companyLogoUrl,
        });
      }
    };

    ws.onopen = () => {
      console.log("WebSocket connection established");
    };

    ws.onclose = () => {
      console.log("WebSocket connection closed");
    };

    return () => {
      ws.close();
    };
  }, []);

  return user;
};

export default useCompany;
