// GET

export const API_GET_USER_DETAILS = "https://qirs.axstudio.xyz/api/user"; // api to get user details
export const API_GET_CUSTOMER_CRM = "https://qirs.axstudio.xyz/api/customersCRM"; // api to get customer details
export const API_GET_RECEIPT = "https://qirs.axstudio.xyz/api/receipts"; // api to get receipt details
export const API_GET_QUOTATION = "https://qirs.axstudio.xyz/api/quotations"; // api to get receipt details
export const API_GET_INVOICE = "https://qirs.axstudio.xyz/api/invoices"; // api to get receipt details
export const API_GET_COUNTER = "https://qirs.axstudio.xyz/api/getCounterData"; // api to get counter details


// CREATE

export const API_POST_NEW_USER = "https://qirs.axstudio.xyz/api/users"; // api to get user details
export const API_POST_NEW_QUOTATION = "https://qirs.axstudio.xyz/api/createQuotation"; // api to Post Document Quotations details
export const API_POST_NEW_INVOICE = "https://qirs.axstudio.xyz/api/createInvoice"; // api to Post Document Quotations details
export const API_POST_NEW_RECEIPT = "https://qirs.axstudio.xyz/api/createReceipt"; // api to Post Document Quotations details
export const API_POST_USER_LOGIN = "https://qirs.axstudio.xyz/api/login"; // api to login user


// UPDATE

export const API_PUT_USER_DETAILS = "https://qirs.axstudio.xyz/api/user/profile"; // api to update user details
export const API_PUT_USER_PASSWORD = "https://qirs.axstudio.xyz/api/user/password"; // api to update user password
export const API_PUT_USER_AVATAR = "https://qirs.axstudio.xyz/api/user/avatar"; // api to update user avatar
export const API_PUT_COMPANY_LOGO = "https://qirs.axstudio.xyz/api/company/logo"; // api to update company logo
export const API_PUT_COMPANY_DETAILS = "https://qirs.axstudio.xyz/api/company/details"; // api to update company logo
export const API_PUT_DOCUMENT_ONGOING = "https://qirs.axstudio.xyz/api/updateOnGoing"; // api to update doc status from pending to OnGoing
export const API_PUT_DOCUMENT_PENDING = "https://qirs.axstudio.xyz/api/updatePending"; // api to update doc status from OnGoing to pending
export const API_PUT_DOCUMENT_DONE = "https://qirs.axstudio.xyz/api/updateDone"; // api to update doc status from OnGoing to Done
export const API_PUT_DOCUMENT_COMPLETED = "https://qirs.axstudio.xyz/api/updateCompleted"; // api to update doc status from OnGoing to Done
export const API_PUT_UPDATE_NOTES = "https://qirs.axstudio.xyz/api/updateDocNotes"; // api to update doc status from OnGoing to Done





// DELETE

export const API_DELETE_DOCUMENT = "https://qirs.axstudio.xyz/api/deleteDocument"; // api to delete user


// TOKEN

export const API_REFRESH_TOKEN = "https://qirs.axstudio.xyz/api/refresh_token"; // api to refresh access token


// UTILITY

export const WEB_SOCKET_URL = "wss://qirs.axstudio.xyz:7700"; // websocket url to connect
export const UPLOAD_DIRECTORY = "https://qirs.axstudio.xyz/uploads/"; // directory to upload files
