import React from "react";
import "./card.css";

const Card = ({
  children,
  width,
  flex,
  className,
  bgColor,
  styles,
  cardTitles,
  cardDescriptions,
  icon,
  borderRadius,
  padding,
  margin,
  onClick,
  iconbtn,
  textbtn,
  onClickBtn,
  height,
  gap,
  btnClassName,
}) => {
  let cardClassName = "card-default-style";
  if (styles === "style-primary") {
    cardClassName = "card-style-primary";
  } else if (styles === "style-glass") {
    cardClassName = "card-style-glass";
  } else if (styles === "style-dashed") {
    cardClassName = "card-style-dashed";
  } else if (styles === "style-hollow") {
    cardClassName = "card-style-hollow";
  }

  const handleRipple = (event) => {
    const button = event.currentTarget;
    const ripple = document.createElement("span");

    const rect = button.getBoundingClientRect();
    const size = Math.max(rect.width, rect.height);

    ripple.style.width = ripple.style.height = `${size}px`;
    ripple.style.left = `${event.clientX - rect.left - size / 2}px`;
    ripple.style.top = `${event.clientY - rect.top - size / 2}px`;
    ripple.classList.add("ripple");

    button.appendChild(ripple);

    setTimeout(() => {
      ripple.remove();
    }, 600); // Match this with the animation duration in CSS
  };

  return (
    <div
      className={`tw-card ${className} ${cardClassName}`}
      style={{
        width: width,
        flex: flex ? 1 : "initial",
        backgroundColor: bgColor,
        borderRadius: borderRadius,
        padding: padding,
        margin: margin,
        gap: gap,
        height: height,
        cursor: onClick ? "pointer" : "default", // To indicate clickable area
      }}
      onClick={onClick}
    >
      {icon && <div className="card-icon">{icon}</div>}
      {cardTitles && <h1 className="card-title">{cardTitles}</h1>}
      {cardDescriptions && (
        <p className="card-description">{cardDescriptions}</p>
      )}

      {children}

      {(iconbtn || textbtn) && (
        <div
          className={`card-action ${btnClassName}`}
          onClick={(event) => {
            handleRipple(event);
            if (onClickBtn) onClick(event);
          }}
        >
          {iconbtn}
          {textbtn &&<p>{textbtn}</p>}
        </div>
      )}
    </div>
  );
};

export default Card;
