import React from "react";
import "./avatar.css";
import { AiOutlineUser } from "react-icons/ai";

const Avatar = ({ width, image, defaultText, onClick }) => {
  const getInitials = (name) => {
    if (!name) return "";
    const words = name.split(" ");
    const initials = words.map((word) => word[0]).join("");
    return initials.toUpperCase();
  };

  const hasImage = image && image.trim() !== "";

  return (
    <div className="avatar-container">
      <div className="avatar" style={{ width: width, height: width }} onClick={onClick}>
        {hasImage ? (
          <img src={image} alt="Avatar" />
        ) : defaultText ? (
          <div
            className="default-text"
            style={{
              width: width,
              height: width,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "2rem",
              backgroundColor: "#ccc",
              borderRadius: "50%",
              color: "white",
            }}
          >
            {getInitials(defaultText)}
          </div>
        ) : (
          <AiOutlineUser size={width} />
        )}
      </div>
    </div>
  );
};

export default Avatar;