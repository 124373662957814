import "./button.css";
import React from "react";

const Button = ({
  styles,
  icon,
  text,
  fontClass,
  onClick,
  type = "button",
}) => {
  let className = "btn-default-style";
  if (styles === "style-one") {
    className = "btn-style-one";
  } else if (styles === "style-two") {
    className = "btn-style-two";
  } else if (styles === "style-three") {
    className = "btn-style-three";
  } else if (styles === "style-hollow") {
    className = "btn-style-hollow";
  }

  const handleRipple = (event) => {
    const button = event.currentTarget;
    const ripple = document.createElement("span");

    const rect = button.getBoundingClientRect();
    const size = Math.max(rect.width, rect.height);

    ripple.style.width = ripple.style.height = `${size}px`;
    ripple.style.left = `${event.clientX - rect.left - size / 2}px`;
    ripple.style.top = `${event.clientY - rect.top - size / 2}px`;
    ripple.classList.add("ripple");

    button.appendChild(ripple);

    setTimeout(() => {
      ripple.remove();
    }, 600); // Match this with the animation duration in CSS
  };

  return (
    <>
      <div className={className}>
        <button
          type={type}
          onClick={(event) => {
            handleRipple(event);
            if (onClick) onClick(event);
          }}
        >
          <div className={`icon ${fontClass}`}>{icon}</div>
          <div className="text">
            <p className={fontClass}>{text}</p>
          </div>
        </button>
      </div>
    </>
  );
};

export default Button;
