import { jwtDecode }  from 'jwt-decode';
import axios from 'axios';
import { API_REFRESH_TOKEN } from '../apiConfig';

export const getUserFromToken = () => {
  const token = localStorage.getItem('accessToken');
  if (!token) return null;

  try {
    return jwtDecode(token);
  } catch (e) {
    console.error('Invalid token:', e);
    return null;
  }
};

export const logout = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  window.location.href = '/';
};

export const refreshAccessToken = async () => {
  try {
    const refreshToken = localStorage.getItem('refreshToken');
    console.log('Refresh Token in localStorage:', refreshToken); // Debugging line
    if (!refreshToken) throw new Error('No refresh token available');

    const response = await axios.post(API_REFRESH_TOKEN, { token: refreshToken });
    localStorage.setItem('accessToken', response.data.accessToken);
    return response.data.accessToken;
  } catch (error) {
    console.error('Failed to refresh access token', error);
    logout();
  }
};