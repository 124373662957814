import React from "react";
import Layout from "../components/layout/layout/layout";
import LayoutCol from "../components/layout/layoutColumn/layoutCol";
import RegisterForm from "../components/projectComponents/Form/registerForm";
const Register = () => {
  return (
    <Layout>
      <LayoutCol
        flex={3}
        bgcolor={"var(--primary-color)"}
        color={"var(--access-color)"}
      >
        <div className="margin-auto">left</div>
      </LayoutCol>
      <LayoutCol flex={2}>
        <RegisterForm />
      </LayoutCol>
    </Layout>
  );
};

export default Register;
