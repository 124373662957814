import React, { useState, useEffect } from "react";
import Layout from "../../layout/layout/layout";
import LayoutRow from "../../layout/layoutRow/layoutRow";
import Avatar from "../../dataDisplay/avatar/avatar";
import useProfile from "../../../utils/userProfile";
import Input from "../../dataInput/input/input";
import { BiSave } from "react-icons/bi";
import Button from "@mui/material/Button";
import axios from "axios";
import Tooltip from "../../dataDisplay/tooltip/tooltip";
import {
  API_PUT_USER_DETAILS,
  API_PUT_USER_PASSWORD,
  API_PUT_USER_AVATAR,
} from "../../../apiConfig";

const UserSettingForm = () => {
  const user = useProfile();
  const isDemoUser = user?.username === "demoUser";

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [loadingPassword, setLoadingPassword] = useState(false);
  const [loadingAvatar, setLoadingAvatar] = useState(false);
  const [error, setError] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleProfileUpdate = async (e) => {
    if (isDemoUser) {
      alert("'demoUser' Does not have permission to change these settings");
      return;
    }
    e.preventDefault();
    setLoadingProfile(true);
    setError(null);
    const { firstName, lastName, email, phoneNumber } = formData;
    try {
      await axios.put(
        API_PUT_USER_DETAILS,
        {
          first_name: firstName,
          last_name: lastName,
          email: email,
          phone_number: phoneNumber,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      alert("Profile updated successfully");
    } catch (error) {
      setError(error.response?.data?.message || "Error updating profile");
    } finally {
      setLoadingProfile(false);
    }
  };

  const handlePasswordChange = async (e) => {
    if (isDemoUser) {
      alert("'demoUser' Does not have permission to change password");
      return;
    }
    e.preventDefault();
    setLoadingPassword(true);
    setError(null);
    const { currentPassword, newPassword, confirmPassword } = formData;
    if (newPassword !== confirmPassword) {
      alert("New password and confirm password do not match");
      setLoadingPassword(false);
      return;
    }
    try {
      await axios.put(
        API_PUT_USER_PASSWORD,
        {
          currentPassword,
          newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      alert("Password updated successfully");
    } catch (error) {
      setError(error.response?.data?.message || "Error updating password");
    } finally {
      setLoadingPassword(false);
    }
  };

  const handleAvatarClick = () => {
    if (isDemoUser) {
      alert("'demoUser' Does not have permission to change profile picture");
      return;
    }
    document.getElementById("avatarUpload").click();
  };

  const handleAvatarChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      setLoadingAvatar(true);
      const formData = new FormData();
      formData.append("default_picture", file);

      try {
        const response = await axios.put(API_PUT_USER_AVATAR, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "Content-Type": "multipart/form-data",
          },
        });
        setAvatar(response.data.avatarUrl);
        alert("Profile picture updated successfully");
      } catch (error) {
        setError(
          error.response?.data?.message || "Error updating profile picture"
        );
      } finally {
        setLoadingAvatar(false);
      }
    }
  };

  useEffect(() => {
    if (user) {
      setFormData({
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        phoneNumber: user.phone_number,
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
      setAvatar(user.avatarUrl);
    }
  }, [user]);

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <Layout direction={"column"} padding={"1rem"}>
      <LayoutRow flex={1}>
        <Layout direction={"column"} flex={1} padding={"2rem"}>
          <Tooltip
            info={
              isDemoUser
                ? "demoUser Does not have permission to change profile picture"
                : "Change profile picture"
            }
          >
            <Avatar
              width={"14rem"}
              image={avatar}
              defaultText={`${user.first_name} ${user.last_name}`}
              onClick={handleAvatarClick}
            />
          </Tooltip>
          <input
            type="file"
            id="avatarUpload"
            style={{ display: "none" }}
            accept="image/*"
            onChange={handleAvatarChange}
          />
          <div className="usernameSettingContainer" style={{ flex: 2 }}>
            <h1>@{user.username}</h1>
          </div>
        </Layout>
        <Layout
          flex={5}
          padding={"1.5rem"}
          className={"settingContent"}
          direction={"column"}
        >
          <div className="titleSettingContainer">
            <h1>Profile Setting</h1>
            <p>Edit your setting by inputting new information and "Update".</p>
          </div>
          <LayoutRow className={"inputSettingContainer"} flex={1}>
            <div className="left">
              <form onSubmit={handleProfileUpdate}>
                <Input
                  styles="style-three"
                  label="First Name"
                  showLabel="true"
                  name="firstName"
                  placeholder="First Name"
                  type="text"
                  width={"80%"}
                  value={formData.firstName}
                  onChange={handleInputChange}
                />
                <Input
                  styles="style-three"
                  label="Last Name"
                  showLabel="true"
                  name="lastName"
                  placeholder="Last Name"
                  type="text"
                  width={"80%"}
                  value={formData.lastName}
                  onChange={handleInputChange}
                />
                <Input
                  styles="style-three"
                  label="Email"
                  showLabel="true"
                  name="email"
                  placeholder="Email"
                  type="text"
                  width={"80%"}
                  value={formData.email}
                  onChange={handleInputChange}
                />
                <Input
                  styles="style-three"
                  label="Phone Number"
                  showLabel="true"
                  name="phoneNumber"
                  placeholder="Phone Number"
                  type="text"
                  width={"80%"}
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                />
                <div className="settingActionBtn">
                  <Button
                    variant="contained"
                    style={{ width: "10rem" }}
                    type="submit"
                    startIcon={<BiSave />}
                  >
                    {loadingProfile ? "Updating..." : "Update"}
                  </Button>
                </div>
                {error && <p style={{ color: "red" }}>{error}</p>}
              </form>
            </div>
            <div className="right">
              {" "}
              <form onSubmit={handlePasswordChange}>
                <p>
                  <b>Note: </b>For changing password, please provide your
                  current password.
                </p>
                <Input
                  styles="style-three"
                  label="Current Password"
                  showLabel="true"
                  name="currentPassword"
                  placeholder="Current Password"
                  type="password"
                  width={"80%"}
                  value={formData.currentPassword}
                  onChange={handleInputChange}
                />
                <Input
                  styles="style-three"
                  label="New Password"
                  showLabel="true"
                  name="newPassword"
                  placeholder="New Password"
                  type="password"
                  width={"80%"}
                  value={formData.newPassword}
                  onChange={handleInputChange}
                />
                <Input
                  styles="style-three"
                  label="Confirm New Password"
                  showLabel="true"
                  name="confirmPassword"
                  placeholder="Confirm New Password"
                  type="password"
                  width={"80%"}
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                />
                <div className="settingActionBtn">
                  <Button
                    variant="contained"
                    style={{ width: "10rem" }}
                    type="submit"
                  >
                    {loadingPassword ? "Confirming..." : "Confirm"}
                  </Button>
                </div>
                {error && <p style={{ color: "red" }}>{error}</p>}
              </form>
            </div>
          </LayoutRow>
        </Layout>
      </LayoutRow>
    </Layout>
  );
};

export default UserSettingForm;
