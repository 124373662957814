import React, { useEffect, useState } from "react";
import Layout from "../components/layout/layout/layout";
import Card from "../components/layout/card/card";
import LayoutRow from "../components/layout/layoutRow/layoutRow";
import TableInvoice from "../components/projectComponents/table/tableInvoice";
import { API_GET_COUNTER } from "../apiConfig";
import axios from "axios";
function Invoice() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCounter = async () => {
      try {
        const response = await axios.get(API_GET_COUNTER, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Adjust if using different auth method
          },
        });
        const result = response.data;
        setData(result);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
        window.location.reload();

      }
    };

    fetchCounter();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <Layout padding={"1rem 1rem 1rem 0rem"} direction={"column"}>
      <LayoutRow flex={1}>
        <Card
          className={"card-qirs"}
          cardTitles={`Total Invoices`}
          flex={1}
          borderRadius={"1.5rem"}
        >
          <div className="counter">
            <p>{data.counterTotalInvoices}</p>
          </div>
        </Card>
        <Card
          className={"card-qirs"}
          flex={1}
          cardTitles={`Total Open Project`}
          borderRadius={"1.5rem"}
        >
          <div className="counter">
            <p>{data.counterTotalOnGoing}</p>
          </div>
        </Card>
        <Card
          className={"card-qirs"}
          flex={1}
          cardTitles={`Active Invoices`}
          borderRadius={"1.5rem"}
        >
          <div className="counter">
            <p>{data.countInvoicesDueInWeek}</p>
          </div>
        </Card>
        <Card
          className={"card-qirs"}
          flex={1}
          cardTitles={`Overdue Payments`}
          borderRadius={"1.5rem"}
        >
          <div className="counter">
            <p>{data.countInvoicesDueBeyondWeek}</p>
          </div>
        </Card>
      </LayoutRow>
      <LayoutRow flex={3}>
        <Card
          className={"card-qirs"}
          flex={1}
          cardTitles={`All Invoices`}
          borderRadius={"1.5rem"}
          cardDescriptions={"All list of created invoices"}
        >
          <TableInvoice />
        </Card>
      </LayoutRow>
    </Layout>
  );
}

export default Invoice;
