import React, { useEffect } from 'react';
import BasicTable from '../../dataDisplay/basicTable/basicTable';

const columns = [
  { header: 'No', accessor: 'no' },
  { header: 'Items', accessor: 'title' },
  { header: 'Description', accessor: 'description' },
  { header: 'Qty', accessor: 'quantity' },
  { header: 'RM', accessor: 'unitPrice' },
];

const TableDocument = ({ setTotal, data }) => {

  const tableData = data.map((item, index) => ({
    no: index + 1,
    title: item.title,
    description: item.description,
    quantity: item.quantity,
    unitPrice: parseFloat(item.unit_price),  // Ensure unit_price is a number
  }));

  console.log("Transformed table data:", tableData);

  useEffect(() => {
    const totalSum = tableData.reduce((sum, item) => sum + item.unitPrice, 0);
    setTotal(totalSum);
  }, [tableData, setTotal]);

  return (
    <div>
      <BasicTable columns={columns} data={tableData} />
    </div>
  );
};

export default TableDocument;
