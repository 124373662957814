import React from "react";
import CreateNewDocument from "../components/projectComponents/Form/createDocumentsForm";
import Layout from "../components/layout/layout/layout";
import Card from "../components/layout/card/card";

const CreateDocuments = ({ documentFor }) => {
  let documentTypes;

  if (documentFor === "Quotation") {
    documentTypes = "createQuotation";
  } else if (documentFor === "Invoice") {
    documentTypes = "createInvoice";
  } else if (documentFor === "Receipt") {
    documentTypes = "createReceipt";
  }

  return (
    <Layout className={"create-quotation"}>
      <Card
        cardTitles={`Create New ${documentFor}`}
        cardDescriptions={"Please fill out the form below"}
        className={"card-qirs"}
        flex={1}
        margin={"0px"}
        borderRadius={"1.5rem"}
      >
        <CreateNewDocument documentType={documentTypes} />
      </Card>
    </Layout>
  );
};

export default CreateDocuments;