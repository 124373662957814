import React, { useEffect, useState } from "react";
import { API_GET_RECEIPT, API_GET_USER_DETAILS } from "../apiConfig"; // Adjust the path as needed
import axios from "axios";

export default function TestDocExport() {
  const [receipts, setReceipts] = useState([]);
  const [selectedReceipt, setSelectedReceipt] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchReceipts = async () => {
      try {
        const response = await axios.get(API_GET_RECEIPT, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Adjust if using different auth method
          },
        });
        setReceipts(response.data);
        if (response.data.length > 0) {
          setSelectedReceipt(response.data[0]); // Select the first receipt for preview
        }
      } catch (error) {
        console.error("Error fetching receipts:", error);
      }
    };

    fetchReceipts();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(API_GET_USER_DETAILS, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });

        const logoUrl = response.data.company_logo
          ? `http://localhost:7700/uploads/${response.data.company_logo}`
          : "";

        setUser({
          ...response.data,
          logoUrl: logoUrl,
        });
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <div>
      {selectedReceipt ? (
        <div className="receipt-container">
          <div className="stylingDocTop"></div>
          <div className="receiptsContent">
            <div className="topSections">
              <div className="left">
                <h2>{user.company_name}</h2>
                <p>{user.company_address}</p>
              </div>
              <div className="right">
                <div className="logoContainer">
                  <img src={user.logoUrl} alt="Company Logo" />
                </div>
              </div>
            </div>
            <h1 className="receipt-header">RECEIPT</h1>
            <div className="receipt-info">
              <div className="left">
                <h2>Receipt for</h2>
                <p>{selectedReceipt.customer_name}</p>
                <p>
                  <b>{selectedReceipt.company_name}</b>
                </p>
                <p>{selectedReceipt.company_address}</p>
              </div>
              <div className="mid">
                <h2>Project</h2>
                <p>{selectedReceipt.project_name}</p>
              </div>
              <div className="right">
                <h2>Receipt ID</h2>
                <p>{selectedReceipt.document_number}</p>
                <h2>Receipt date</h2>
                <p>{selectedReceipt.due_date}</p>
              </div>
            </div>
            <div className="receipt-items">
              <h2></h2>
              <div className="label">
                <h2 style={{ flex: 5, textAlign: "left" }}>Description</h2>
                <h2 style={{ flex: 1 }}>Qty</h2>
                <h2 style={{ flex: 1 }}>Unit price</h2>
                <h2 style={{ flex: 1, textAlign: "right" }}>Total price</h2>
              </div>

              {selectedReceipt.items.map((item, index) => (
                <div className="receipt-item" key={index}>
                  <div
                    className="description"
                    style={{ flex: 5, textAlign: "left" }}
                  >
                    <h3 style={{ fontSize: "1rem" }}>{item.title}</h3>
                    <p>{item.description}</p>
                  </div>
                  <div
                    className="quantity"
                    style={{ flex: 1, textAlign: "center" }}
                  >
                    <p>{item.quantity}</p>
                  </div>
                  <div
                    className="unit-price"
                    style={{ flex: 1, textAlign: "center" }}
                  >
                    <p>
                      {user.curency} {item.unit_price}
                    </p>
                  </div>
                  <div
                    className="total"
                    style={{ flex: 1, textAlign: "right" }}
                  >
                    <p>
                      {user.curency} {item.total_price_items}
                    </p>
                  </div>
                </div>
              ))}
              <h2></h2>
            </div>
            <div className="receipt-footer">
              <div className="notes" style={{ flex: 6 }}>
                <p>Notes:</p>
                <p> {selectedReceipt.notes}</p>
              </div>
              <div className="label">
                <p>
                  <strong style={{ textAlign: "left !important" }}>
                    Subtotal:
                  </strong>
                </p>
                <p>
                  <strong style={{ textAlign: "left  !important" }}>
                    Discount:
                  </strong>
                </p>
 
              </div>
              <div
                className="total-price"
                style={{ textAlign: "right", width: "10rem" }}
              >
                <p>
                  {user.curency} {selectedReceipt.sub_totals}
                </p>
                <p>{selectedReceipt.discounts} %</p>
                <h1 style={{ fontSize: "2rem" }}>
                  {user.curency} {selectedReceipt.total_price}
                </h1>
              </div>
            </div>
          </div>
          <div className="stylingDocSpacing"></div>
          <div className="docFooterText">
            <p>design by Putra Naaim</p>
          </div>
          <div className="stylingDocBottom"></div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}
