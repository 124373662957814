import React, { useState } from "react";
import LayoutCol from "../../layout/layoutColumn/layoutCol";
import Input from "../../dataInput/input/input";
import Button from "../../dataInput/button/button";
import { API_POST_NEW_USER } from "../../../apiConfig";
import axios from "axios";

const RegisterForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [defaultPicture, setDefaultPicture] = useState(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false); // New state for success message

  const handleRegister = async (event) => {
    event.preventDefault(); // Prevent the default form submission
    setError(""); // Clear previous errors

    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("email", email);
    formData.append("phone_number", phoneNumber);
    if (defaultPicture) {
      formData.append("default_picture", defaultPicture);
    }

    try {
      const response = await axios.post(
        API_POST_NEW_USER,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Registration response:", response.data); // Log the response data
      setSuccess(true); // Set success to true upon successful registration
    } catch (err) {
      console.error("Registration error:", err.response || err); // Log the error
      setError("Registration failed");
    }
  };

  return (
    <LayoutCol className={"loginForm"}>
      <h1>Register</h1>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {success ? (
        <div>
          <p style={{ color: "green" }}>Registration successful!</p>
          <a href="/">Proceed to login</a>
        </div>
      ) : (
        <form onSubmit={handleRegister}>
          <LayoutCol gap={"1rem"} className={"loginInput"}>
            <Input
              styles="style-one"
              width={"23rem"}
              label="Username"
              showLabel="true"
              placeholder="Username"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <Input
              styles="style-one"
              width={"23rem"}
              label="Email"
              showLabel="true"
              placeholder="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              styles="style-one"
              width={"23rem"}
              label="Password"
              showLabel="true"
              placeholder="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Input
              styles="style-one"
              width={"23rem"}
              label="First Name"
              showLabel="true"
              placeholder="First Name"
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <Input
              styles="style-one"
              width={"23rem"}
              label="Last Name"
              showLabel="true"
              placeholder="Last Name"
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />

            <Input
              styles="style-one"
              width={"23rem"}
              label="Phone Number"
              showLabel="true"
              placeholder="Phone Number"
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />

            <input
              type="file"
              accept="image/*"
              onChange={(e) => setDefaultPicture(e.target.files[0])}
            />
          </LayoutCol>
          <div className="loginBtnContainer">
            <Button
              styles="style-one"
              text={"Register"}
              fontClass={"text1"}
              type="submit"
            />
          </div>
        </form>
      )}
    </LayoutCol>
  );
};

export default RegisterForm;
