import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import {
  API_GET_QUOTATION,
  API_GET_USER_DETAILS,
  API_DELETE_DOCUMENT,
  API_PUT_DOCUMENT_ONGOING,
  API_PUT_DOCUMENT_DONE,
  API_PUT_DOCUMENT_PENDING,
} from "../../../apiConfig";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete"; // Import the Delete icon
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { generatePDF } from "../../../documentGenerate/quotationTemplate";

const TableQuotation = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [receipts, setReceipts] = useState([]);

  const deleteReceipt = async (documentId) => {
    try {
      await axios.delete(`${API_DELETE_DOCUMENT}/${documentId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      // Remove the deleted receipt from the state
      setRows((prevRows) =>
        prevRows.filter((row) => row.document_id !== documentId)
      );
    } catch (error) {
      console.error("Error deleting receipt:", error);
    }
  };

  const updateStatus = async (documentId) => {
    try {
      await axios.put(
        `${API_PUT_DOCUMENT_ONGOING}/${documentId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      // Update the status of the row in the state
      setRows((prevRows) =>
        prevRows.map((row) =>
          row.document_id === documentId ? { ...row, status: "OnGoing" } : row
        )
      );
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const updateStatus2 = async (documentId) => {
    try {
      await axios.put(
        `${API_PUT_DOCUMENT_PENDING}/${documentId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      // Update the status of the row in the state
      setRows((prevRows) =>
        prevRows.map((row) =>
          row.document_id === documentId ? { ...row, status: "Pending" } : row
        )
      );
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const updateStatus3 = async (documentId) => {
    try {
      await axios.put(
        `${API_PUT_DOCUMENT_DONE}/${documentId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      // Remove the document from the state
      setRows((prevRows) =>
        prevRows.filter((row) => row.document_id !== documentId)
      );
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  useEffect(() => {
    const fetchReceipts = async () => {
      try {
        const response = await axios.get(API_GET_QUOTATION, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Adjust if using different auth method
          },
        });
        setReceipts(response.data);
        setRows(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching receipts:", error);
      }
    };

    fetchReceipts();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(API_GET_USER_DETAILS, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });

        const logoUrl = response.data.company_logo
          ? `https://qirs.axstudio.xyz/uploads/${response.data.company_logo}`
          : "";

        setUser({
          ...response.data,
          logoUrl: logoUrl,
        });
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const handleExportPDF = (entry) => {
    generatePDF(entry, user, entry);
  };

  const columns = [
    { field: "document_number", headerName: "Document ID", flex: 2 },
    { field: "date_created", headerName: "Doc Created", flex: 2 },
    { field: "project_name", headerName: "Project Name", flex: 1 },
    { field: "customer_name", headerName: "Customer Name", flex: 1 },
    { field: "company_name", headerName: "Company Name", flex: 1 },
    { field: "discounts", headerName: "Discounts", flex: 2 },
    { field: "total_price", headerName: "Total Price", flex: 2 },
    { field: "status", headerName: "Status", flex: 1 },
    {
      field: "actions",
      headerName: "",
      flex: 3,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleExportPDF(params.row)}>
            <PictureAsPdfIcon />
          </IconButton>
          <IconButton onClick={() => deleteReceipt(params.row.document_id)}>
            <DeleteIcon />
          </IconButton>
          {params.row.status !== "Pending" && (
            <IconButton onClick={() => updateStatus2(params.row.document_id)}>
              <ArrowBackIosIcon />
            </IconButton>
          )}
          {params.row.status === "Pending" && (
            <IconButton onClick={() => updateStatus(params.row.document_id)}>
              <ArrowForwardIosIcon />
            </IconButton>
          )}
          {params.row.status === "OnGoing" && (
            <IconButton onClick={() => updateStatus3(params.row.document_id)}>
              <ArrowForwardIosIcon />
            </IconButton>
          )}
        </>
      ),
    },
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    discounts: true, // Initially hide the 'email' column. to hide state use false
  });

  return (
    <div style={{ flex: 1 }}>
      <DataGrid
        rows={rows}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
        columns={columns}
        loading={loading}
        getRowId={(row) => row.document_id}
      />
    </div>
  );
};

export default TableQuotation;
