import React, { useEffect, useState } from "react";
import Layout from "../components/layout/layout/layout";
import Card from "../components/layout/card/card";
import LayoutRow from "../components/layout/layoutRow/layoutRow";
import TableReceipt from "../components/projectComponents/table/tableReceipt";
import { API_GET_COUNTER, API_GET_USER_DETAILS } from "../apiConfig";
import axios from "axios";
function Receipt() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(API_GET_USER_DETAILS, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });

        setUser({
          ...response.data,
        });
      } catch (error) {
        console.error("Error fetching user data:", error);
        window.location.reload();
      }
    };

    fetchUserData();
  }, []);
  useEffect(() => {
    const fetchCounter = async () => {
      try {
        const response = await axios.get(API_GET_COUNTER, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Adjust if using different auth method
          },
        });
        const result = response.data;
        setData(result);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
        window.location.reload();
      }
    };

    fetchCounter();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <Layout padding={"1rem 1rem 1rem 0rem"} direction={"column"}>
      <LayoutRow flex={1}>
        <Card
          className={"card-qirs"}
          cardTitles={`Total Receipts`}
          flex={1}
          borderRadius={"1.5rem"}
        >
          <div className="counter">
            <p>{data.counterTotalOnGoing}</p>
          </div>
        </Card>
        <Card
          className={"card-qirs"}
          flex={1}
          cardTitles={`Total Open Project`}
          borderRadius={"1.5rem"}
        >
          <div className="counter">
            <p>{data.counterTotalOnGoing}</p>
          </div>
        </Card>
        <Card
          className={"card-qirs"}
          flex={1}
          cardTitles={`Monthly Sales`}
          borderRadius={"1.5rem"}
        >
          {data.sumCompletedTotalPriceMonthly.map((monthData, index) => (
            <div className="counter" key={index}>
              <p>
                {user.curency} {monthData.total_completed_price_sum}
              </p>
            </div>
          ))}
        </Card>
        <Card
          className={"card-qirs"}
          flex={1}
          cardTitles={`Target Monthly Sales`}
          borderRadius={"1.5rem"}
        >
          {data.sumTotalPriceMonthly.map((monthData, index) => (
            <div className="counter" key={index}>
              <p>
                {user.curency} {monthData.total_price_sum}
              </p>
            </div>
          ))}
        </Card>
      </LayoutRow>
      <LayoutRow flex={3}>
        <Card
          className={"card-qirs"}
          flex={1}
          cardTitles={`All Receipts`}
          borderRadius={"1.5rem"}
          cardDescriptions={"All list of created receipts"}
        >
          <TableReceipt />
        </Card>
      </LayoutRow>
    </Layout>
  );
}

export default Receipt;
