import React from "react";
import Layoutrow from "../layout/layoutRow/layoutRow";
import Avatar from "../dataDisplay/avatar/avatar";
import Button from "@mui/material/Button";
import { BiBookAdd, BiBell } from "react-icons/bi";
import useProfile from "../../utils/userProfile";
import useCurrentTime from "../../utils/currentTime";
import { NavLink } from "react-router-dom";

function Header() {
  const user = useProfile();
  const currentTime = useCurrentTime();

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <Layoutrow height={"6rem"}>
      <div className="name-container">
        <h1>
          Welcome, {user.first_name} {user.last_name}!
        </h1>
        <p>{currentTime}</p>
      </div>
      <div className="rightHeader-container">
        <NavLink to="/main/createQuotation">
          <Button
            startIcon={<BiBookAdd />}
            style={{ width: "8rem" }}
            variant="contained"
          >
            Create
          </Button>
        </NavLink>

        <Avatar
          width={"4rem"}
          image={user.avatarUrl}
          defaultText={`${user.first_name} ${user.last_name}`}
        />
      </div>
    </Layoutrow>
  );
}

export default Header;
