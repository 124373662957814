import React, { useState } from 'react';
import './tabs.css';

const Tabs = ({ tabs, styles, className, onClick, contentPadding, bgcolor }) => {
  const [activeTab, setActiveTab] = useState(0);

  let styleClassName = "tabs-default-style";
  if (styles === "style-one") {
    styleClassName = "tabs-style-one";
  } else if (styles === "style-two") {
    styleClassName = "tabs-style-two";
  } else if (styles === "style-default") {
    styleClassName = "tabs-default-style";
  }

  const handleRipple = (event) => {
    const button = event.currentTarget;
    const ripple = document.createElement("span");

    const rect = button.getBoundingClientRect();
    const size = Math.max(rect.width, rect.height);

    ripple.style.width = ripple.style.height = `${size}px`;
    ripple.style.left = `${event.clientX - rect.left - size / 2}px`;
    ripple.style.top = `${event.clientY - rect.top - size / 2}px`;
    ripple.classList.add("ripple");

    button.appendChild(ripple);

    setTimeout(() => {
      ripple.remove();
    }, 600); // Match this with the animation duration in CSS
  };

  return (
    <div className={`tabs ${className} ${styleClassName}`} style={{ backgroundColor: bgcolor }}>
      <div className='tab-buttons'>
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`tab-button ${activeTab === index ? 'active' : ''}`}
            disabled={'disabled' in tab} // Check for existence of `disabled` prop
            onClick={(event) => {
              if (!('disabled' in tab)) { // Only handle click if tab is not disabled
                handleRipple(event);
                setActiveTab(index);
                if (onClick) onClick(event);
              }
            }}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className="tab-content" style={{ padding: contentPadding }}>
        {tabs[activeTab].content}
      </div>
    </div>
  );
};

export default Tabs;