import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import {
  API_GET_INVOICE,
  API_GET_USER_DETAILS,
  API_DELETE_DOCUMENT,
  API_PUT_DOCUMENT_COMPLETED,
  API_PUT_UPDATE_NOTES,
} from "../../../apiConfig";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete"; // Import the Delete icon
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { generatePDF } from "../../../documentGenerate/invoiceTemplate";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const TableInvoice = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [receipts, setReceipts] = useState([]);

  const deleteReceipt = async (documentId) => {
    try {
      await axios.delete(`${API_DELETE_DOCUMENT}/${documentId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      // Remove the deleted receipt from the state
      setRows((prevRows) =>
        prevRows.filter((row) => row.document_id !== documentId)
      );
    } catch (error) {
      console.error("Error deleting receipt:", error);
    }
  };

  const updateStatus = async (documentId) => {
    try {
      await axios.put(
        `${API_PUT_DOCUMENT_COMPLETED}/${documentId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      // Update the status of the row in the state
      setRows((prevRows) =>
        prevRows.filter((row) => row.document_id !== documentId)
      );
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const updateNotes = async (documentId, notes) => {
    try {
      await axios.put(
        `${API_PUT_UPDATE_NOTES}/${documentId}`,
        { notes },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
    } catch (error) {
      console.error("Error updating notes:", error);
    }
  };

  useEffect(() => {
    const fetchReceipts = async () => {
      try {
        const response = await axios.get(API_GET_INVOICE, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Adjust if using different auth method
          },
        });
        setReceipts(response.data);
        setRows(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching receipts:", error);
      }
    };

    fetchReceipts();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(API_GET_USER_DETAILS, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });

        const logoUrl = response.data.company_logo
          ? `https://qirs.axstudio.xyz/uploads/${response.data.company_logo}`
          : "";

        setUser({
          ...response.data,
          logoUrl: logoUrl,
        });
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const handleExportPDF = (entry) => {
    generatePDF(entry, user, entry);
  };

  const processRowUpdate = async (newRow, oldRow) => {
    if (newRow.notes !== oldRow.notes) {
      await updateNotes(newRow.document_id, newRow.notes);
    }
    return newRow;
  };

  const columns = [
    { field: "document_number", headerName: "Document ID", flex: 2 },
    { field: "due_date", headerName: "Due Date", flex: 2 },
    { field: "project_name", headerName: "Project Name", flex: 2 },
    { field: "customer_name", headerName: "Customer Name", flex: 2 },
    { field: "company_name", headerName: "Company Name", flex: 2 },
    { field: "total_price", headerName: "Total Price", flex: 2 },
    { field: "status", headerName: "Status", flex: 2 },
    { field: "notes", headerName: "Notes", flex: 2, editable: true },
    {
      field: "actions",
      headerName: "",
      flex: 3,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleExportPDF(params.row)}>
            <PictureAsPdfIcon />
          </IconButton>
          <IconButton onClick={() => deleteReceipt(params.row.document_id)}>
            <DeleteIcon />
          </IconButton>
          <IconButton onClick={() => updateStatus(params.row.document_id)}>
            <ArrowForwardIosIcon />
          </IconButton>
        </>
      ),
    },
  ];
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    discounts: true, // Initially hide the 'email' column. to hide state use false
  });

  return (
    <div style={{ flex: 1 }}>
      <DataGrid
        rows={rows}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
        columns={columns}
        loading={loading}
        getRowId={(row) => row.document_id}
        processRowUpdate={processRowUpdate}

      />
    </div>
  );
};

export default TableInvoice;
