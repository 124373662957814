import React, { useEffect, useState } from "react";
import Layout from "../components/layout/layout/layout";
import Card from "../components/layout/card/card";
import LayoutRow from "../components/layout/layoutRow/layoutRow";
import TableQuotation from "../components/projectComponents/table/tableQuotation";
import { API_GET_COUNTER, API_GET_USER_DETAILS } from "../apiConfig";
import axios from "axios";

function Quotations() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(API_GET_USER_DETAILS, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });

        setUser({
          ...response.data,
        });
      } catch (error) {
        console.error("Error fetching user data:", error);
        window.location.reload();
      }
    };

    fetchUserData();
  }, []);


  useEffect(() => {
    const fetchCounter = async () => {
      try {
        const response = await axios.get(API_GET_COUNTER, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Adjust if using different auth method
          },
        });
        const result = response.data;
        setData(result);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
        window.location.reload();
      }
    };

    fetchCounter();
  }, []);


  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <Layout padding={"1rem 1rem 1rem 0rem"} direction={"column"}>
      <LayoutRow flex={1}>
        <Card
          className={"card-qirs"}
          cardTitles={`Total Quotations`}
          flex={1}
          borderRadius={"1.5rem"}
        >
          <div className="counter">
            <p>{data.counterTotalQuotations}</p>
          </div>
        </Card>
        <Card
          className={"card-qirs"}
          flex={1}
          cardTitles={`Total Open Project`}
          borderRadius={"1.5rem"}
        >
          <div className="counter">
            <p>{data.counterTotalOnGoing}</p>
          </div>
        </Card>
        <Card
          className={"card-qirs"}
          flex={1}
          cardTitles={`Current Sales`}
          borderRadius={"1.5rem"}
        >
          <div className="counter">
            <p>
              {user.curency} {data.sumCompletedTotalPrice}
            </p>
          </div>
        </Card>
        <Card
          className={"card-qirs"}
          flex={1}
          cardTitles={`Expected Sales`}
          borderRadius={"1.5rem"}
        >
          <div className="counter">
            <p>
              {user.curency} {data.sumTotalPrice}
            </p>
          </div>
        </Card>
      </LayoutRow>
      <LayoutRow flex={3}>
        <Card
          className={"card-qirs"}
          flex={1}
          cardTitles={`All Quotations`}
          borderRadius={"1.5rem"}
          cardDescriptions={"All list of created quotations"}
        >
          <TableQuotation />
        </Card>
      </LayoutRow>
    </Layout>
  );
}

export default Quotations;
