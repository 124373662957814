import React, { useEffect, useState } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import axios from 'axios';
import { API_GET_COUNTER } from '../../../apiConfig';

const chartSetting = {
  xAxis: [
    {
      label: 'Amount (RM)',
    },
  ],
  flex: 1,
  height: 200,
};

const valueFormatter = (value) => `RM ${value.toFixed(2)}`;

export default function ChartTargetSales() {
  const [dataset, setDataset] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(API_GET_COUNTER, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Adjust if using different auth method
          },
        });
        const responseData = response.data;

        if (responseData.sumTotalPrice !== undefined && responseData.sumCompletedTotalPrice !== undefined) {
          const sumTotalPrice = parseFloat(responseData.sumTotalPrice);
          const sumCompletedTotalPrice = parseFloat(responseData.sumCompletedTotalPrice);

          // Check if the fetched values are valid numbers
          if (isNaN(sumTotalPrice) || isNaN(sumCompletedTotalPrice)) {
            console.error('Invalid data received:', { sumTotalPrice, sumCompletedTotalPrice });
            setDataset([]);
            return;
          }

          // Prepare the data based on the API response
          const chartData = [
            {
              category: 'Target',
              value: sumTotalPrice,
              color: '#3f51b5', // Color for the 'Target' bar
            },
            {
              category: 'Current',
              value: sumCompletedTotalPrice,
              color: '#ff5722', // Color for the 'Current' bar
            }
          ];

          setDataset(chartData);
        } else {
          setDataset([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setDataset([]);
      }
    };

    fetchData();
  }, []);

  if (dataset.length === 0) {
    return <div style={{ textAlign: "center", flex: 1, margin: "auto", alignContent: "center", paddingBottom: "3rem" }}>No data available to display.</div>;
  }

  return (
    <BarChart
      dataset={dataset}
      yAxis={[{ scaleType: 'band', dataKey: 'category' ,  }]}
      series={[{ dataKey: 'value', valueFormatter, color: 'gray' },]}
      layout="horizontal"
      {...chartSetting}
    />
  );
}
