import React from "react";
import './layoutCol.css';

function LayoutCol({ children, flex, className, gap, color, bgcolor, height, padding }) {
    return (
        <div style={{ flex: flex, gap: gap, color: color, backgroundColor: bgcolor, height: height, padding: padding }} className={`layout-col ${className}`}>
            {children}
        </div>
    );
};

export default LayoutCol;