import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const DataGrid = () => {
  const [formData, setFormData] = useState({
    customerName: "",
    phoneNumber: "",
    companyName: "",
    companyAddress: "",
    projectName: "",
    items: [
      {
        id: 1,
        title: "",
        description: "",
        quantity: 0,
        unitPrice: 0,
        totalPrice: 0,
        isActive: true,
      },
    ],
    discount: 0,
    notes: "",
    status: "Completed",
    email: "",
  });

  const handleInputChange = (e, index, field) => {
    const value = e.target.value;
    const updatedItems = formData.items.map((item, i) => {
      if (i === index) {
        const updatedItem = { ...item, [field]: value };
        if (field === "quantity" || field === "unitPrice") {
          updatedItem.totalPrice = updatedItem.quantity * updatedItem.unitPrice;
        }
        return updatedItem;
      }
      return item;
    });
    setFormData({ ...formData, items: updatedItems });
  };

  const handleDiscountChange = (e) => {
    const discount = parseFloat(e.target.value) || 0;
    setFormData({ ...formData, discount });
  };

  const handleAddRow = () => {
    const newItem = {
      id: formData.items.length + 1,
      title: "",
      description: "",
      quantity: 0,
      unitPrice: 0,
      totalPrice: 0,
      isActive: true,
    };
    setFormData({ ...formData, items: [...formData.items, newItem] });
  };

  const handleDeleteRow = (index) => {
    const updatedItems = formData.items.filter((_, i) => i !== index);
    setFormData({ ...formData, items: updatedItems });
  };

  const calculateSubtotal = () => {
    return formData.items.reduce((sum, item) => sum + item.totalPrice, 0);
  };

  const calculateGrandTotal = (subtotal, discount) => {
    return subtotal - (subtotal * discount) / 100;
  };

  const subtotal = calculateSubtotal();
  const grandTotal = calculateGrandTotal(subtotal, formData.discount);

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Unit Price</TableCell>
              <TableCell>Total Price</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {formData.items.map((item, index) => (
              <TableRow key={item.id}>
                <TableCell>
                  <TextField
                    value={item.title}
                    onChange={(e) => handleInputChange(e, index, "title")}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    value={item.description}
                    onChange={(e) => handleInputChange(e, index, "description")}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    value={item.quantity}
                    onChange={(e) => handleInputChange(e, index, "quantity")}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    value={item.unitPrice}
                    onChange={(e) => handleInputChange(e, index, "unitPrice")}
                  />
                </TableCell>
                <TableCell>{item.totalPrice}</TableCell>
                <TableCell>
                  <IconButton
                    color="secondary"
                    onClick={() => handleDeleteRow(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={6}>
                <Button onClick={handleAddRow} variant="contained" color="primary">
                  Add Row
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={3}>
        <Typography variant="h6">Subtotal: {subtotal.toFixed(2)}</Typography>
        <TextField
          label="Discount (%)"
          type="number"
          value={formData.discount}
          onChange={handleDiscountChange}
          style={{ marginTop: "20px", marginBottom: "20px" }}
        />
        <Typography variant="h6">Grand Total: {grandTotal.toFixed(2)}</Typography>
      </Box>
    </div>
  );
};

export default DataGrid;
