import React from "react";
import "./sideMenu.css";

const SubMenu = ({
  children,
  borderTop,
  borderBottom,
  height,
  title,
  flex,
  marginBottom,
  marginTop,
}) => {
  return (
    <div
      className="sub-menu-container"
      style={{
        borderTop: borderTop,
        borderBottom: borderBottom,
        flex: flex,
        marginBottom: marginBottom,
        marginTop: marginTop,
      }}
    >
      <div
        className="sub-menu"
        style={{
          height: height,
        }}
      >
        <h1 className="sub-menu-title">{title}</h1>
        {children}
      </div>
    </div>
  );
};

export default SubMenu;
