import React from 'react';
import PropTypes from 'prop-types';
import './basicTable.css';

const BasicTable = ({ columns, data, className }) => {
  console.log("Rendering table with data:", data);
  return (
    <table className={`custom-table ${className}`}>
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th key={index}>{column.header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, rowIndex) => (
          <tr key={rowIndex}>
            {columns.map((column, colIndex) => (
              <td key={colIndex}>
                {typeof item[column.accessor] === 'string' || typeof item[column.accessor] === 'number' ? (
                  item[column.accessor]
                ) : (
                  item[column.accessor]
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

BasicTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
};

export default BasicTable;
