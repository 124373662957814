import React from "react";
import Layout from "../components/layout/layout/layout";
import LayoutCol from "../components/layout/layoutColumn/layoutCol";
import LoginForm from "../components/projectComponents/Form/loginForm";
const Login = () => {
  return (
    <Layout className={"loginContentContent"}>
      <LayoutCol
        flex={3}
        bgcolor={"var(--primary-color)"}
        color={"var(--access-color)"}
        className={"hiddenMobile"}
      >
        <div className="margin-auto login-text">
          <h1>Hello!</h1>
          <p>
            Welcome to my portfolio web app! 🎉 I'm thrilled to have you here.
            Feel free to explore the site using the following credentials:
          </p>
          <div className="demo-credentials">
            <p>
              <b>Username:</b> demoUser
            </p>
            <p>
              <b>Password:</b> password123
            </p>
          </div>

          <p>
            Please note that this project is purely for showcasing my work. If
            you have any questions or would like to get in touch, you can reach
            me, at <b>axwan.putranaaim@gmail.com</b>.
          </p>
          <p>Enjoy exploring, and thank you for visiting!</p>
        </div>
      </LayoutCol>
      <LayoutCol flex={2} className={"hiddenMobile"}>
        <LoginForm />
      </LayoutCol>
      <Layout className={"showMobile hidden"}>
        <h1>Sorry!</h1>
        <p>This web-app are specifically designed for desktop</p>
        <p>Please visit back using your laptop or pc</p>
        <p><b>qirs.axstudio.xyz</b></p>

      </Layout>
    </Layout>
  );
};

export default Login;
