import React, { useState } from 'react';
import './tooltip.css';

const Tooltip = ({ info, children }) => {
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const showTooltip = () => {
    setVisible(true);
  };

  const hideTooltip = () => {
    setVisible(false);
  };

  const handleMouseMove = (e) => {
    setPosition({ x: e.clientX, y: e.clientY });
  };

  return (
    <div
      className="tooltip-container"
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      onMouseMove={handleMouseMove}
    >
      {children}
      {visible && (
        <div
          className="tooltip-box"
          style={{ top: `${position.y + 10}px`, left: `${position.x + 10}px` }}
        >
          {info}
        </div>
      )}
    </div>
  );
};

export default Tooltip;