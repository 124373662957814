import React from "react";
import "./input.css";

const Input = ({ styles, label, type, placeholder, showLabel, width, value, onChange, name }) => {
  let className = "input-default-style";
  if (styles === "style-one") {
    className = "input-style-one";
  } else if (styles === "style-two") {
    className = "input-style-two";
  } else if (styles === "style-three") {
    className = "input-style-three";
  } else if (styles === "style-hollow") {
    className = "input-style-hollow";
  }

  return (
    <div className={className} style={{ width: width }}>
      {showLabel === "true" && <label className="show" htmlFor={name}>{label}</label>}
      <input
        type={type}
        id={name}
        name={name} // Ensure the input has a name attribute
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default Input;