import React, { useState } from "react";
import LayoutCol from "../../layout/layoutColumn/layoutCol";
import Input from "../../dataInput/input/input";
import logoColor from "../../../asset/logoColor.png";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_POST_USER_LOGIN } from "../../../apiConfig";

const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate(); // useNavigate hook

  const handleLogin = async (event) => {
    event.preventDefault();
    setError("");
    try {
      const response = await axios.post(API_POST_USER_LOGIN, {
        username,
        password,
      });
      console.log("API Response:", response.data); // Debugging line
      const { accessToken, refreshToken } = response.data;
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken); // Ensure refresh token is stored
      console.log("Access Token:", accessToken); // Debugging line
      console.log("Refresh Token:", refreshToken); // Debugging line
      console.log(
        "Token in localStorage:",
        localStorage.getItem("accessToken")
      ); // Debugging line
      navigate("/main/dashboard");
    } catch (err) {
      console.error("Login error:", err);
      setError("Invalid Password or Username");
    }
  };

  return (
    <LayoutCol className={"loginForm"}>
      <div className="imgContainer">
        <img src={logoColor} alt="" />
      </div>
      <h1>Welcome</h1>
      <p>Please enter your details.</p>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <form onSubmit={handleLogin}>
        <LayoutCol gap={"1rem"} className={"loginInput"}>
          <Input
            styles="style-one"
            width={"23rem"}
            label="Username"
            showLabel="true"
            placeholder="username"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <Input
            styles="style-one"
            width={"23rem"}
            label="Password"
            showLabel="true"
            placeholder="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </LayoutCol>
        <div className="loginBtnContainer" style={{ display: "flex", justifyContent: "center", }}>
          <Button variant="contained" style={{ width: "10rem", }} onClick={handleLogin}>
            Login
          </Button>
        </div>
      </form>
    </LayoutCol>
  );
};

export default LoginForm;
