import axios from 'axios';
import { API_POST_NEW_INVOICE, API_POST_NEW_QUOTATION, API_POST_NEW_RECEIPT } from '../apiConfig';

export const handleCreateNewDocument = async (e, formData, endpoint) => {
    e.preventDefault();
    const token = localStorage.getItem('accessToken'); //this is the token from the local storage... need to get this first token

    if (!token) {
        console.error('No token found');
        return;
    }

    // Determine the full endpoint URL based on the provided endpoint
    let url;
    if (endpoint === 'createReceipt') {
        url = API_POST_NEW_RECEIPT;
    } else if (endpoint === 'createInvoice') {
        url = API_POST_NEW_INVOICE;
    } else if (endpoint === 'createQuotation') {
        url = API_POST_NEW_QUOTATION;
    } else {
        console.error('Invalid endpoint provided');
        return;
    }

    try {
        const response = await axios.post(url, formData, {
            headers: { Authorization: `Bearer ${token}` }
        });
        console.log(response.data);
        return { success: true, message: 'Document created successfully' };
    } catch (err) {
        console.error(err);
        return { success: false, message: 'Error creating document' };
    }
};