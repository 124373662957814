import React from "react";
import Tabs from "../navi/tabs/tabs";
import Layout from "../layout/layout/layout";
import UserSettingForm from "./Form/userSettingForm";
import DocumentSettingForm from "./Form/documentSettingForm";

const TabComponent = () => {
  const tabs = [
    { label: "Profile Setting", content: <UserSettingForm /> },
    { label: "Document Setting",  content: <DocumentSettingForm /> },
  ];

  return (
    <Layout className={"qirs-content"} >
      <Tabs tabs={tabs} styles={"style-default"} />
    </Layout>
  );
};

export default TabComponent;
