import React, { useState, useEffect } from "react";
import { Button, TextField, FormControl } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { handleCreateNewDocument } from "../../../utils/apiList";

const getCurrentTime = (addDays = 0) => {
  const now = new Date();
  now.setDate(now.getDate() + addDays);

  const day = String(now.getDate()).padStart(2, "0");
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const year = now.getFullYear();
  let hours = now.getHours();


  return `${day}/${month}/${year} `;
};

const CreateNewDocument = ({ documentType }) => {
  const addDays =
    documentType === "createQuotation" || documentType === "createInvoice"
      ? 7
      : 0;
  const [currentTime, setCurrentTime] = useState(getCurrentTime(addDays));

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(getCurrentTime(addDays));
    }, 60000);
    return () => clearInterval(interval);
  }, [addDays]);

  const initialStatus = () => {
    switch (documentType) {
      case "createQuotation":
        return "Pending";
      case "createInvoice":
        return "Done";
      case "createReceipt":
        return "Completed";
      default:
        return "Completed";
    }
  };

  const [formData, setFormData] = useState({
    customerName: "",
    phoneNumber: "",
    companyName: "",
    companyAddress: "",
    projectName: "",
    items: [
      {
        id: 1,
        title: "",
        description: "",
        quantity: 0,
        unitPrice: 0,
        totalPrice: 0,
        isActive: true,
      },
    ],
    discount: 0,
    notes: "",
    status: initialStatus(),
    email: "",
    dueDate: currentTime,
  });

  const handleInputChange = (e, index, field) => {
    const value = e.target.value;
    const updatedItems = formData.items.map((item, i) => {
      if (i === index) {
        const updatedItem = { ...item, [field]: value };
        if (field === "quantity" || field === "unitPrice") {
          updatedItem.totalPrice = updatedItem.quantity * updatedItem.unitPrice;
        }
        return updatedItem;
      }
      return item;
    });
    setFormData({ ...formData, items: updatedItems });
  };

  const handleAddRow = () => {
    const newItem = {
      id: formData.items.length + 1,
      title: "",
      description: "",
      quantity: 0,
      unitPrice: 0,
      totalPrice: 0,
      isActive: true,
    };
    setFormData({ ...formData, items: [...formData.items, newItem] });
  };

  const handleDeleteRow = (index) => {
    const updatedItems = formData.items.filter((_, i) => i !== index);
    setFormData({ ...formData, items: updatedItems });
  };

  const calculateSubtotal = () => {
    return formData.items.reduce((sum, item) => sum + item.totalPrice, 0);
  };

  const calculateGrandTotal = (subtotal, discount) => {
    return subtotal - (subtotal * discount) / 100;
  };

  const handleDiscountChange = (e) => {
    const discount = parseFloat(e.target.value) || 0;
    setFormData({ ...formData, discount });
  };
  //

  const subtotal = calculateSubtotal();
  const grandTotal = calculateGrandTotal(subtotal, formData.discount);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  let documentLabel;
  if (documentType === "createQuotation") {
    documentLabel = "Quotation";
  } else if (documentType === "createInvoice") {
    documentLabel = "Invoice";
  } else if (documentType === "createReceipt") {
    documentLabel = "Receipt";
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await handleCreateNewDocument(e, formData, documentType);
    if (response.success) {
      alert(`${documentLabel} successfully created`);
      window.location.reload(); // Auto refresh the page
    } else {
      alert(`Error: ${response.message}`);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="create-document-form">
      <div className="create-document-form">
        <div className="left">
          <p>Customer Information</p>
          <FormControl>
            <TextField
              label="Customer Name"
              name="customerName"
              value={formData.customerName}
              onChange={handleChange}
              fullWidth
            />
          </FormControl>
          <FormControl>
            <TextField
              label="Phone Number"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              fullWidth
            />
          </FormControl>

          <FormControl>
            <TextField
              label="Email"
              name="customerEmail"
              value={formData.customerEmail}
              onChange={handleChange}
              fullWidth
            />
          </FormControl>
          <p>Organizations Information</p>

          <FormControl>
            <TextField
              label="Company Name"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              fullWidth
            />
          </FormControl>
          <FormControl>
            <TextField
              label="Company Address"
              name="companyAddress"
              value={formData.companyAddress}
              onChange={handleChange}
              fullWidth
            />
          </FormControl>
          <p>Project Name</p>

          <FormControl>
            <TextField
              label="Project Name"
              name="projectName"
              value={formData.projectName}
              onChange={handleChange}
              fullWidth
            />
          </FormControl>
        </div>
        <div className="mid">
          <TableContainer className="tableItem">
            <Table>
              <TableHead style={{ position: "sticky", top: 0, boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}>
                <TableRow>
                  <TableCell>Items</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Unit Price</TableCell>
                  <TableCell>Total Price</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formData.items.map((item, index) => (
                  <TableRow key={item.id}>
                    <TableCell>
                      <TextField
                        value={item.title}
                        onChange={(e) => handleInputChange(e, index, "title")}
                        placeholder="Please write title (Required!)"
                      />
                      <TextField
                        style={{ marginTop: "3.5px" }}
                        value={item.description}
                        placeholder="Please write description"
                        onChange={(e) =>
                          handleInputChange(e, index, "description")
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={item.quantity}
                        onChange={(e) =>
                          handleInputChange(e, index, "quantity")
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={item.unitPrice}
                        onChange={(e) =>
                          handleInputChange(e, index, "unitPrice")
                        }
                      />
                    </TableCell>
                    <TableCell>{item.totalPrice}</TableCell>
                    <TableCell>
                      <IconButton
                        color="secondary"
                        onClick={() => handleDeleteRow(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button onClick={handleAddRow} variant="contained" color="primary">
            Add items
          </Button>

          <div className="totalContainer">
            <p>Total</p>
            <h2>{subtotal.toFixed(2)}</h2>
          </div>
        </div>
        <div className="right">
          <p>Discount</p>

          <FormControl>
            <TextField
              label="Discount (%)"
              type="number"
              value={formData.discount}
              onChange={handleDiscountChange}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            />
          </FormControl>
          <div className="grandTotalContainer">
            <p>Grand Total</p>
            <h2>{grandTotal.toFixed(2)}</h2>
          </div>
          <p>Project Notes</p>

          <FormControl>
            <TextField
              label="Notes"
              name="notes"
              value={formData.notes}
              onChange={handleChange}
              fullWidth
              multiline
              rows={4}
            />
          </FormControl>
          <Button type="submit" variant="contained" color="primary">
            Create {documentLabel}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default CreateNewDocument;
