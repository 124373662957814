import "./app.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Register from "./pages/register";
import Login from "./pages/login";
import Index from "./pages/main";
import Test from "./components/test";
import ProtectedRoute from "./components/projectComponents/protectedRoute/protectedRoute";

function App() {
  return (
    <>
      <div className="container">
        <Router>
          <Routes>
            <Route
              path="/main/*"
              element={
                <ProtectedRoute>
                  <Index />
                </ProtectedRoute>
              }
            />
            <Route path="/register" element={<Register />} />
            <Route path="/" element={<Login />} />
            <Route path="/test" element={<Test />} />
          </Routes>
        </Router>
      </div>
    </>
  );
}

export default App;
