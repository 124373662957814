import React, { useState, useEffect, useRef } from "react";
import Layout from "../../layout/layout/layout";
import LayoutRow from "../../layout/layoutRow/layoutRow";
import Input from "../../dataInput/input/input";
import Card from "../../layout/card/card";
import { BiAddToQueue } from "react-icons/bi";
import TableDocument from "../table/tableDocument";
import Button from "../../dataInput/button/button";
import axios from "axios";
import Modal from "../../../modal"; // Import the modal component

const TestingItemsForm = () => {
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [unitPrice, setUnitPrice] = useState(0);

  const ws = useRef(null);

  useEffect(() => {
    ws.current = new WebSocket("ws://localhost:7700");

    ws.current.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === "item") {
        console.log("WebSocket received item:", message.data);
        setData((prevData) => {
          const newData = [...prevData, message.data];
          console.log("Updated data:", newData);
          return newData;
        });
      } else if (message.type === "user") {
        // Handle user update if needed
      }
    };

    ws.current.onopen = () => {
      console.log("WebSocket connection established");
    };

    ws.current.onclose = () => {
      console.log("WebSocket connection closed");
    };

    return () => {
      ws.current.close();
    };
  }, []);

  const handleAddItem = (e) => {
    e.preventDefault();

    const newItem = {
      title,
      description,
      qty: parseInt(quantity, 10),
      price: parseFloat(unitPrice),
    };

    console.log("Submitting new item:", newItem);

    const token = localStorage.getItem("accessToken");

    axios
      .post("http://localhost:7700/api/items", newItem, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("Item added successfully:", response.data);
        setShowModal(false);
      })
      .catch((error) => {
        console.error("Error adding item:", error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "quantity") {
      setQuantity(value);
    } else if (name === "unitPrice") {
      setUnitPrice(value);
    } else if (name === "title") {
      setTitle(value);
    } else if (name === "description") {
      setDescription(value);
    }
    console.log("Updated field:", name, value);
  };

  return (
    <Layout>
      <LayoutRow className={"createNewDocument"} padding={"0px"}>
        <div className="mid">
          <p>Items:</p>
          <Card
            styles={"style-dashed"}
            padding={"10px"}
            borderRadius={"5px"}
            flex={1}
            margin={"0px"}
            iconbtn={<BiAddToQueue />}
            onClick={() => setShowModal(true)}
          >
            <TableDocument setTotal={setTotal} data={data} />
          </Card>
          <div className="totalContainer">
            <p>Total:</p>
            <h1>RM {total.toFixed(2)}</h1>
          </div>
        </div>
      </LayoutRow>

      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <form onSubmit={handleAddItem}>
          <Input
            styles="style-one"
            label="Title"
            showLabel="true"
            placeholder="Item Title"
            type="text"
            name="title"
            value={title}
            onChange={handleChange}
          />
          <Input
            styles="style-one"
            label="Description"
            showLabel="true"
            placeholder="Item Description"
            type="text"
            name="description"
            value={description}
            onChange={handleChange}
          />
          <div className="input-style-one">
            <label className="show" htmlFor="quantity">
              Quantity
            </label>
            <input
              type="number"
              id="quantity"
              name="quantity"
              placeholder="Quantity"
              value={quantity}
              onChange={handleChange}
            />
          </div>
          <div className="input-style-one">
            <label className="show" htmlFor="unitPrice">
              Unit Price
            </label>
            <input
              type="number"
              id="unitPrice"
              name="unitPrice"
              placeholder="Unit Price"
              value={unitPrice}
              onChange={handleChange}
            />
          </div>
          <Button
            type="submit"
            styles=""
            text={"Add Item"}
            fontClass={"text1"}
          />
        </form>
      </Modal>
    </Layout>
  );
};

export default TestingItemsForm;
