import React from "react";
import "./sideMenu.css";
import { NavLink } from "react-router-dom";

const createRipple = (event) => {
  const container = event.currentTarget;
  const ripple = document.createElement("span");

  const rect = container.getBoundingClientRect();
  const size = Math.max(rect.width, rect.height);

  ripple.style.width = ripple.style.height = `${size}px`;
  ripple.style.left = `${event.clientX - rect.left - size / 2}px`;
  ripple.style.top = `${event.clientY - rect.top - size / 2}px`;
  ripple.classList.add("ripple");

  container.appendChild(ripple);

  setTimeout(() => {
    ripple.remove();
  }, 600); // Match this with the animation duration in CSS
};

const ItemMenu = ({ icon, text, route, onClick }) => {
  return (
    <NavLink
      to={route}
      className={({ isActive }) => (isActive ? "nav-link nav-link-active" : "nav-link")}
      onClick={onClick}
    >
      <div className="item-menu" onClick={createRipple}>
        {icon} <p>{text}</p>
      </div>
    </NavLink>
  );
};

export default ItemMenu;