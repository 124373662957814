import React from "react";  
import "./layout.css";

const Layout = ({ children, className, gap, direction, flex, color, bgcolor, height, padding }) => {
    return (
        <div className={`layout-main ${className}`} style={{ gap: gap, flex: flex, flexDirection: direction, backgroundColor: bgcolor, color: color, height: height, padding: padding }}>
            {children}
        </div>
    );
};

export default Layout;