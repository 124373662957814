import React from "react";
import Layout from "../components/layout/layout/layout";
import LayoutCol from "../components/layout/layoutColumn/layoutCol";
import TabComponent from "../components/projectComponents/tab";

const Setting = () => {

    return (
        <Layout>
            <LayoutCol flex={1} >
                <TabComponent />

            </LayoutCol>
        </Layout>

    );
};

export default Setting;