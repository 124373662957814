import React from "react";
import Dashboard from "./dashboard";
import Layout from "../components/layout/layout/layout";
import LayoutCol from "../components/layout/layoutColumn/layoutCol";
import Menu from "../components/projectComponents/menu";
import { Routes, Route } from "react-router-dom";

import Header from "../components/projectComponents/header";
import Setting from "./setting";
import CreateDocuments from "./createDocuments";
import TestingItemsForm from "../components/projectComponents/Form/testingItemsForm";
import CRM from "./crm";
import Receipt from "./receipt";
import TestDocExport from "./testDocExport";
import TestDocDoc from "../components/test";
import Invoice from "./invoice";
import Quotations from "./quotations";

function Index() {
  return (
    <Layout gap={"5px"} bgcolor={"var(--access-color)"}>
      <Menu />

      <LayoutCol flex={1}>
        <Header />

        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/settings" element={<Setting />} />
          <Route path="/createQuotation" element={<CreateDocuments documentFor={"Quotation"} />} />
          <Route path="/createInvoice" element={<CreateDocuments documentFor={"Invoice"} />} />
          <Route path="/createReceipt" element={<CreateDocuments documentFor={"Receipt"} />} />
          <Route path="/testingtable" element={<TestingItemsForm />} />
          <Route path="/crm" element={<CRM />} />
          <Route path="/receipt" element={<Receipt />} />
          <Route path="/invoice" element={<Invoice />} />
          <Route path="/quotation" element={<Quotations />} />
          <Route path="/testDocs" element={<TestDocExport />} />
          <Route path="docuTest" element={<TestDocDoc />} />


        </Routes>
      </LayoutCol>
    </Layout>
  );
}

export default Index;
