import React from "react";
import "./layoutRow.css";

function LayoutRow({ children, flex, className, gap, color, bgcolor, height, padding }) {
    return (
        <div style={{ flex: flex, gap: gap, backgroundColor: bgcolor, color: color, height: height, padding: padding }} className={`layout-row ${className}`}>
            {children}
        </div>
    );
};

export default LayoutRow;